// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


const initBackTop = () => {
  const button = document.querySelector('.back-top');

  if (!button) {
    return;
  }

  button.addEventListener('click', () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    button.blur();
  });
};

const toggleBackTop = () => {
  const button = document.querySelector('.back-top');

  if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
    button.style.opacity = '1';
  } else {
    button.style.opacity = '0';
  }
}

const initOffers = () => {
  const links = document.querySelectorAll('#offer-nav a');
  const textContainers = document.querySelectorAll('#offer-nav .content-nav__text');

  for (const link of links) {
    link.addEventListener('click', (e) => {
      if (window.innerWidth >= 992) {
        return;
      }

      e.preventDefault();

      const activeLink = document.querySelector('#offer-nav .active');

      for (const link of links) {
        link.classList.remove('active');
      }

      for (const textContainer of textContainers) {
        textContainer.innerText = '';
      }

      if (activeLink === link) {
        return;
      }

      link.classList.add('active');

      const currentTextContainer = link.closest('.content-nav__item').querySelector('.content-nav__text');

      if (currentTextContainer) {
        currentTextContainer.innerHTML = currentTextContainer.dataset.text;
      }
    });
  }
}

const initCookies = () => {
  const key = 'cookies-accepted';
  const cookiesWrapper = document.getElementById('cookies');
  const button = cookiesWrapper.querySelector('button');

  if (!localStorage.getItem(key)) {
    cookiesWrapper.classList.remove('-hidden');
  }

  if (button) {
    button.addEventListener('click', () => {
      localStorage.setItem(key, 'true');
      cookiesWrapper.classList.add('-hidden');
    });
  }
}

document.addEventListener('turbolinks:load', () => {
  initBackTop();
  initOffers();
  initCookies();

  window.onscroll = () => { toggleBackTop() };
});